import React from "react"
import Layout from "../../../components/common/Layout"
import Cursor from "../../../components/common/Cursor"
import Footer from "../../../components/common/Footer"
import BtnMain from "../../../components/common/BtnMain"
import Modal from "../../../components/section/careers/restaurant/Modal"
import Opportunities from "../../../components/section/careers/restaurant/Opportunities"
import Initiative from "../../../components/section/careers/restaurant/Initiative"
import Inside from "../../../components/section/careers/restaurant/Inside"
import Xnum from "../../../components/section/careers/restaurant/Xnum"
import Saas from "../../../components/section/careers/farm/Saas"
import ArrowYDown from "../../../components/common/ArrowYDown"

class Job {
  constructor(name, url) {
    this.name = name
    this.url = url
  }
}

const jobList = [
  new Job("レストランマネージャー（店舗責任者）", "https://herp.careers/v1/crisp/1XSX2XP8tgNK"),
  new Job("アシスタント・レストランマネージャー（店舗責任者候補）", "https://herp.careers/v1/crisp/jG9pw_ww4JSQ"),
  new Job("レストラン運営アルバイトメンバー（東京都）", "https://sites.google.com/crisp.co.jp/recruit-ap/recruit"),
]

export default function Restaurant() {
  let title = "CAREERS RESTAURANT"
  let description = "接客を次のステージへ。新しいレストラン体験を実装する。"
  let page = "careers__restaurant"
  let under = "p-under"
  let headParent = "CAREERS"
  let headTtl = "RESTAURANT"
  let blockTtl =
    '接客を次のステージへ。<br class="u-none__sp" />新しいレストラン体験を実装する。'
  let blockTxt =
    "私たちにとって一番大切なことは、店舗での体験価値を上げることです。そのためにデータやテクノロジーも積極的に取り入れています。「RESTAURANT」のパートナーに求められるのは、どうしたらもっと良い顧客体験を提供できるかを考え、実行できる人。例えば、お客様のLTVを10倍にするためなら、データに基づいて既存のルールを越えた判断と実践ができる。そんな意思と現場経験を持っている方には最適な環境です。"
  let dark = "js-scroll__dark"
  let kvSrc = "/asset/img/careers/restaurant/kv.jpg"
  let kvSrc2x = "/asset/img/careers/restaurant/kv@2x.jpg"
  let kvSrcWebp = "/asset/img/careers/restaurant/kv.jpg"
  let kvSrcWebp2x = "/asset/img/careers/restaurant/kv@2x.jpg"
  let kvAlt = "店内で接客するスタッフ"

  return (
    <Layout title={title} description={description}>
      <div id="page">
        <div id="careers" className="js-page careers__under">
          <main id="main">
            <div id="contents" className="contents">
              <Cursor/>
              <Modal/>

              <section
                className={`p-common-kv p-careers__restaurant-kv ${under}`}
              >
                <div className="p-common-kv__wrapper">
                  <div className="p-common-kv__head">
                    <span className="p-common-kv__head__parent">
                      {headParent}
                    </span>
                    <h1 className="p-common-kv__head-ttl">
                      <div dangerouslySetInnerHTML={{__html: headTtl}}/>
                    </h1>
                  </div>
                  <div className="p-common-kv__block">
                    <div className="p-common-kv__content">
                      <h2 className="c-ttl__main p-common-kv__content-ttl">
                        <div dangerouslySetInnerHTML={{__html: blockTtl}}/>
                      </h2>
                      <p className="c-txt__main p-common-kv__content-txt">
                        <div dangerouslySetInnerHTML={{__html: blockTxt}}/>
                      </p>
                    </div>
                  </div>
                  <button
                    className="js-hover p-common-kv__arrow"
                    data-updown-trigger="kv"
                    data-hover-in-time="600"
                    aria-label="スクロールダウンボタン"
                  >
                    <ArrowYDown/>
                  </button>
                  <div
                    className={` ${dark} js-hover p-common-kv__img `}
                    data-updown-target="kv"
                    data-hover-in-time="600"
                  >
                    <span className="p-common-kv__img-start__normal"/>
                    <button className="c-link__img p-common-kv__img-btn">
                      <picture>
                        <img
                          className="c-img__def p-common-kv__img-item p-common-kv__img-item__trigger p-common-kv__img-item__careers__restaurant"
                          src={`${kvSrc}`}
                          srcSet={`${kvSrc} 1x, ${kvSrc2x} 2x`}
                          alt={`${kvAlt}`}
                          loading="lazy"
                        />
                      </picture>
                    </button>
                  </div>
                </div>
              </section>
              <Opportunities
                jobList={jobList}
              />
              <Initiative/>
              <Inside/>
              <Xnum/>
              <Saas/>
              <BtnMain/>
            </div>
          </main>
          <Footer/>
        </div>
      </div>
    </Layout>
  )
}
